export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        // icon: 'vuestic-iconset-dashboard',
        // icon: 'fa-chalkboard',
        // icon: 'dashboard',
        // icon: 'mso-dashboard',
        // icon: 'bar_chart',
        // icon: 'mso-newsmode',
        icon: 'dvr',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        // icon: 'vuestic-iconset-dashboard',
        // icon: 'fa-users',
        // icon: 'fa-user-group',
        // icon: 'group',
        icon: 'mso-group',
      },
    },
    {
      name: 'products',
      displayName: 'menu.products',
      meta: {
        // icon: 'fa-box-open',
        // icon: 'widgets',
        icon: 'mso-widgets',
        // icon: 'mso-package_2',
      },
    },
    {
      name: 'shipments',
      displayName: 'menu.shipments',
      meta: {
        // icon: 'fa-truck',
        // icon: 'local_shipping',
        icon: 'mso-local_shipping',
      },
    },
    {
      name: 'files',
      displayName: 'menu.files',
      meta: {
        // icon: 'fa-file',
        // icon: 'vuestic-iconset-files',
        // icon: 'folder_open',
        icon: 'mso-note_stack', // material-symbols-outlined: note_stack
        // icon: 'sharp_note_stack', // material-symbols-sharp: note_stack
        // icon: 'round_note_stack', // material-symbols-rounded: note_stack
        // icon: 'edit_document',
      },
    },
    {
      name: 'forms',
      displayName: 'menu.forms',
      meta: {
        // icon: 'fa-list-alt',
        // icon: 'edit_note',
        // icon: 'feed',
        // icon: 'mso-edit',
        // icon: 'mso-edit_document',
        // icon: 'edit_document',
        // icon: 'text_snippet',
        // icon: 'mso-feed',
        // icon: 'mso-contract_edit',
        // icon: 'mso-edit_note',
        // icon: 'mso-note_alt',
        // icon: 'mso-edit_document',
        icon: 'mso-edit_square',
      },
      children: [
        {
          name: 'airWaybill',
          displayName: 'menu.airWaybill',
        },
        {
          name: 'billOfLading',
          displayName: 'menu.billOfLading',
        },
        {
          name: 'certOfOrigin',
          displayName: 'menu.certOfOrigin',
        },
      ],
    },
    // {
    //   name: 'health',
    //   displayName: 'menu.health',
    //   meta: {
    //     icon: 'fa-heartbeat',
    //   },
    // },
    {
      name: 'did',
      displayName: 'menu.did',
      meta: {
        // icon: 'fa-fingerprint',
        icon: 'fingerprint',
      },
    },
    {
      // name: 'credentials',
      // displayName: 'menu.credentials',
      name: 'template',
      displayName: 'menu.template',
      meta: {
        // icon: 'fa-id-card',
        // icon: 'badge',
        // icon: 'mso-id_card', // material-symbols-outlined: id_card
        // icon: 'mso-grid_guides',
        // icon: 'mso-content_copy',
        icon: 'mso-copy_all',
      },
      children: [
        {
          name: 'schema',
          displayName: 'menu.schema',
        },
        {
          name: 'manifest',
          displayName: 'menu.manifest',
        },
        // {
        //   name: 'template',
        //   displayName: 'menu.template',
        // },
      ],
    },
    {
      name: 'verifiableCredential',
      displayName: 'menu.verifiableCredential',
      meta: {
        // icon: 'fa-fingerprint',
        // icon: 'fa-id-card',
        // icon: 'badge',
        icon: 'mso-id_card', // material-symbols-outlined: id_card
      },
    },
    // {
    //   name: 'verification',
    //   displayName: 'menu.verification',
    //   meta: {
    //     // icon: 'fa-check-circle',
    //     // icon: 'verified_user',
    //     icon: 'mso-verified_user',
    //   },
    //   children: [
    //     {
    //       name: 'submissionLink',
    //       displayName: 'menu.submissionLink',
    //     },
    //     {
    //       name: 'submission',
    //       displayName: 'menu.submission',
    //     },
    //   ],
    // },
    {
      name: 'presentation',
      displayName: 'menu.presentation',
      meta: {
        // icon: 'fa-check-circle',
        // icon: 'verified_user',
        icon: 'mso-verified_user',
      },
      children: [
        {
          name: 'request',
          displayName: 'menu.request',
        },
        {
          name: 'submission',
          displayName: 'menu.submission',
        },
      ],
    },
    // {
    //   name: 'chat2',
    //   displayName: 'menu.chat2',
    //   meta: {
    //     // icon: 'fa-comment-dots',
    //     icon: 'chat',
    //   },
    // },
    // {
    //   name: 'tbdex',
    //   displayName: 'menu.tbdex',
    //   meta: {
    //     // icon: 'mso-monetization_on',
    //     // icon: 'mso-request_quote',
    //     // icon: 'attach_money',
    //     icon: 'currency_exchange',
    //   },
    // },
    {
      name: 'tbdex',
      displayName: 'menu.tbdex',
      meta: {
        // icon: 'mso-monetization_on',
        // icon: 'mso-request_quote',
        // icon: 'attach_money',
        icon: 'currency_exchange',
      },
      children: [
        {
          name: 'pfis',
          displayName: 'menu.pfis',
        },
        {
          name: 'offerings',
          displayName: 'menu.offerings',
        },
        {
          name: 'rfqs',
          displayName: 'menu.rfqs',
        },
      ],
    },
    {
      name: 'help',
      displayName: 'menu.help',
      meta: {
        // icon: 'fa-circle-question',
        // icon: 'quiz',
        // icon: 'mso-quiz',
        // icon: 'help',
        // icon: 'mso-help',
        icon: 'mso-error',
      },
      // children: [
      //   {
      //     name: 'learnMore',
      //     displayName: 'dashboard.info.learnMore',
      //   },
      //   {
      //     name: 'workflow',
      //     displayName: 'menu.workflow',
      //   },
      // ],
    },
    {
      name: 'learnMore',
      displayName: 'menu.learnMore',
      meta: {
        // icon: 'fa-circle-question',
        // icon: 'quiz',
        // icon: 'mso-quiz',
        // icon: 'help',
        // icon: 'mso-help',
        icon: 'mso-error',
      },
    },
    {
      name: 'workflow',
      displayName: 'menu.workflow',
      meta: {
        // icon: 'fa-circle-question',
        // icon: 'quiz',
        // icon: 'mso-quiz',
        // icon: 'help',
        // icon: 'mso-help',
        icon: 'mso-error',
      },
    },
    {
      name: 'faq',
      displayName: 'menu.faq',
      meta: {
        // icon: 'fa-circle-question',
        // icon: 'quiz',
        // icon: 'mso-quiz',
        // icon: 'help',
        // icon: 'mso-help',
        icon: 'mso-error',
      },
    },
    {
      name: 'projects',
      displayName: 'menu.projects',
      meta: {
        // icon: 'fa-circle-question',
        // icon: 'quiz',
        // icon: 'mso-quiz',
        // icon: 'help',
        // icon: 'mso-help',
        icon: 'mso-error',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'credit_card',
      },
      children: [
        {
          name: 'payment-methods',
          displayName: 'menu.payment-methods',
        },
        {
          name: 'pricing-plans',
          displayName: 'menu.pricing-plans',
        },
        {
          name: 'billing',
          displayName: 'menu.billing',
        },
      ],
    },
    // {
    //   name: 'auth',
    //   displayName: 'menu.auth',
    //   meta: {
    //     icon: 'login',
    //   },
    //   children: [
    //     {
    //       name: 'login',
    //       displayName: 'menu.login',
    //     },
    //     {
    //       name: 'signup',
    //       displayName: 'menu.signup',
    //     },
    //     {
    //       name: 'recover-password',
    //       displayName: 'menu.recover-password',
    //     },
    //   ],
    // },
    // },
    // {
    //   name: '404',
    //   displayName: 'menu.404',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   },
    // },
    {
      name: 'preferences',
      // displayName: 'menu.preferences',
      displayName: 'menu.profile',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
    // {
    //   name: 'chat',
    //   displayName: 'menu.chat',
    //   meta: {
    //     // icon: 'fa-comment-dots',
    //     // icon: 'chat',
    //     icon: 'mso-chat',
    //   },
    // },
  ] as INavigationRoute[],
}
