<!-- sc: 20240227 - references -->
<!-- https://ui.vuestic.dev/styles/colors -->
<!-- https://ui.vuestic.dev/ui-elements/switch -->
<template>
  <div class="flex items-center gap-3">
    <div class="flex items-center gap-3">
      <!-- <VaPopover class="mr-2 mb-2" message="Switch color theme" color="#252723"> -->
      <!-- color="#5123a1"
      off-color="#ffd300"
      style="--va-switch-checker-background-color: #252723;" -->
      <!-- style="--va-switch-checker-background-color: #5123a1" -->
      <!-- style="--va-switch-checker-background-color: #ffd300" -->
      <!-- style="--va-switch-checker-background-color: #252723" -->
      <VaSwitch
        v-model="switchValue"
        color="#5123a1"
        style="--va-switch-checker-background-color: #ffffff"
        true-value="dark"
        false-value="light"
        size="small"
      >
        <template #innerLabel>
          <div class="va-text-center">
            <!-- {{ value }} -->
            <!-- <VaIcon :name="value ? 'dark_mode' : 'light_mode'" /> -->
            <!-- {{ switchValue }} -->
            <!-- <VaIcon :name="switchValue === 'dark' ? 'dark_mode' : 'light_mode'" /> -->
            <VaIcon :name="switchValue === 'light' ? 'dark_mode' : 'light_mode'" />
          </div>
        </template>
      </VaSwitch>
      <!-- </VaPopover> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { useColors } from 'vuestic-ui'
import { computed } from 'vue'

const { applyPreset, currentPresetName } = useColors()

const switchValue: any = computed({
  get() {
    return currentPresetName.value
  },
  set(value) {
    // console.log('value:', value)
    applyPreset(value)
  },
})
</script>

<script lang="ts">
export default {
  data() {
    return {
      value: true,
    }
  },
}
</script>
