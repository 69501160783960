{
  "auth": {
    "agree": "Acepto",
    "createAccount": "Create cuenta",
    "createNewAccount": "Crear cuenta",
    "email": "Email",
    "login": "Iniciar sesión",
    "password": "Contraseña",
    "signUp": "Registrar",
    "termsOfUse": "Los terminos de uso"
  },
  "buttons": {
    "advanced": "Iconos, Grupos, Dropdowns",
    "button": "BOTON",
    "colors": "Colores",
    "disabled": "DESHABILITADO",
    "dropdown": "DESPLEGABLE",
    "hover": "HOVER",
    "main": "Botón Primario y Secundario",
    "micro": "MICRO",
    "pressed": "PRESIONADO",
    "small": "PEQUEÑO"
  },
  "charts": {
    "bubbleChart": "Bubble Chart",
    "donutChart": "Donut Chart",
    "horizontalBarChart": "Horizontal Bar Chart",
    "lineChart": "Line Chart",
    "pieChart": "Pie Chart",
    "verticalBarChart": "Vertical Bar Chart"
  },
  "collapse": {
    "accordion": "Acordeón",
    "buttons": "Botones"
  },
  "dashboard": {
    "elements": "Elementos",
    "success": "ÉXITO",
    "successMessage": "Ha leído correctamente este mensaje de alerta importante.",
    "teamMembers": "Miembros del Equipo",
    "versions": "Versiones",
    "info": {
      "verify": "Verificar",
      "revoke": "Revocar",
      "exploreGallery": "Explorar galería",
      "dids": ",Identificadores descentralizados",
      "vcs": "Credenciales verificables",
      "pr": "Solicitudes de presentación",
      "learnMore": "Aprende más",
      "learnMoreText": "Un emisor puede proporcionar credenciales verificables a un titular y luego un verificador puede verificar la credencial mediante una solicitud de presentación. Este proceso se basa en identificadores descentralizados (DID).",
      "shipmentsFrom": "Envíos desde"
    },
    "table": {
      "title": "Mesa impresionante",
      "brief": "Breve",
      "detailed": "Detallado",
      "resolve": "Resolver",
      "resolved": "Resuelto"
    }
  },
  "notificationsPage": {
    "alerts": {
      "title": "Alerts",
      "success": "ÉXITO",
      "successMessage": "Ha leído correctamente este mensaje de alerta importante.",
      "info": "INFO",
      "infoMessage": "Esta alerta necesita tu atención, pero no es muy importante.",
      "warning": "ADVERTENCIA",
      "warningMessage": "Mejor compruébalo, esto no pinta bien.",
      "danger": "PELIGRO",
      "dangerMessage": "Cambiar algunas cosas e enviarlo de nuevo."
    },
    "popovers": {
      "title": "Tooltips & Popovers",
      "popoverTitleLabel": "Title de Popover",
      "popoverTextLabel": "Texto de Popover",
      "popoverIconLabel": "Icono de Popover (fontawesome)",
      "showPopover": "Mostrar Popover",
      "topTooltip": "tope tooltip",
      "rightTooltip": "derecha tooltip",
      "leftTooltip": "izquierda",
      "bottomTooltip": "fondo"
    },
    "Toasts": {
      "title": "tostadas",
      "textLabel": "Texto",
      "durationLabel": "Duración (milisegundos)",
      "iconLabel": "Icono (fontawesome)",
      "fullWidthLabel": "Ancho total",
      "launchToast": "Lanzar tostadas"
    }
  },
  "forms": {
    "controls": {
      "title": "Checkboxes, Radios, Switches",
      "radioDisabled": "Radio Desactivado",
      "radio": "Radio",
      "subscribe": "Suscribirse a la newsletter"
    },
    "inputs": {
      "emailValidatedSuccess": "Email (validado con éxito)",
      "emailValidated": "Email (validado)",
      "inputWithIcon": "Input con Icono",
      "inputWithButton": "Input con Boton",
      "inputWithClearButton": "Input With Clear Button",
      "inputWithRoundButton": "Input With Round Button",
      "textInput": "Input de Texto",
      "textInputWithDescription": "Input de Texto (con descripcion)",
      "textArea": "Text Area",
      "title": "Inputs",
      "upload": "SUBIR"
    },
    "mediumEditor": {
      "title": "Medium Editor"
    },
    "selects": {
      "country": "País Select",
      "countryMulti": "País Multi Select",
      "multi": "Multi Select",
      "simple": "Select Simple",
      "title": "Selects"
    }
  },
  "grid": {
    "fixed": "Grid Fijo",
    "desktop": "Desktop Grid",
    "responsive": "Responsive Grid",
    "offsets": "Offsets"
  },
  "icons": {
    "back": "Volver a los iconos",
    "none": "No se han encontrado iconos",
    "search": "Búsqueda de iconos",
    "title": "Iconos"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "Inglesa",
    "french": "Francesa",
    "spanish": "Spanish",
    "simplified_chinese": "Mandarina",
    "persan": "Persan"
  },
  "menu": {
    "users": "El usuarios",
    "products": "Productos",
    "shipments": "Envíos",
    "health": "Salud",
    "did": "Identificadores descentralizados",
    "credentials": "Cartas credenciales",
    "schema": "Esquema",
    "manifest": "Plantilla",
    "template": "Plantilla",
    "verifiableCredential": "Credencial verificable",
    "verification": "Verificación",
    "presentation": "Presentación",
    "help": "Ayuda",
    "supplyChain": "Cadena de suministro",
    "workflow": "Flujo de trabajo",
    "faq2": "Preguntas más frecuentes",
    "submissionLink": "Solicitud de presentación",
    "submission": "Envío",
    "file": "Archivo",
    "files": "Archivos",
    "forms2": "Formularios",
    "upload": "Subir",
    "upload2": "Subir2",
    "billOfLading": "Guía de carga",
    "certOfOrigin": "Certificado de origen",
    "airWaybill": "Carta de porte aéreo",
    "profile": "Perfil",

    "auth": "Autenticación",
    "buttons": "Botones",
    "charts": "Gráficos",
    "collapse": "Colapso",
    "colorPickers": "Selector de color",
    "dashboard": "Panel",
    "formElements": "Elementos de un Formulario",
    "forms": "Formularios",
    "mediumEditor": "Medium Editor",
    "grid": "Grid",
    "icons": "Iconos",
    "cards": "Cards",
    "login": "Iniciar sesión",
    "maps": "Mapas",
    "modals": "Modals",
    "notifications": "Notificaciones",
    "progressBars": "Barras de Progreso",
    "signUp": "Registrar",
    "statistics": "Estadisticas",
    "lists": "Listas",
    "tables": "Tablas",
    "markupTables": "Tablas de Marcado",
    "dataTables": "Tablas de Datos",
    "typography": "Tipografía",
    "uiElements": "Elementos UI",
    "colors": "Colores",
    "TBDex": "TBDex",
    "pfis": "PFIs",
    "offerings": "Ofrendas",
    "chat": "Chat"
  },
  "messages": {
    "all": "Ver todos los mensajes",
    "new": "Nuevo mensajes de {name}"
  },
  "modal": {
    "cancel": "CANCELAR",
    "close": "CERRAR",
    "confirm": "CONFIRMAR",
    "large": "Largo",
    "largeTitle": "Modal Largo",
    "medium": "Mediano",
    "mediumTitle": "Modal Mediana",
    "small": "Pequeño",
    "static": "Estático",
    "smallTitle": "Modal Pequeña",
    "staticMessage": "Esto es un modal estático. No se cerrará haciendo clic en el fondo.",
    "staticTitle": "Modal Estático",
    "title": "Modals"
  },
  "fileUpload": {
    "advancedMediaGallery": "Avanzado, Galería de medios",
    "advancedUploadList": "Avanzado, lista de carga",
    "mediaGallery": "Galería media",
    "uploadList": "Lista de carga",
    "single": "Soltero",
    "dragNdropFiles": "Arrastrar y soltar archivos",
    "uploadedOn": "Subido en",
    "fileDeleted": "El archivo fue eliminado exitosamente",
    "undo": "Deshacer",
    "preview": "Avance",
    "delete": "Borrar",
    "deleteFile": "Borrar archivo",
    "uploadFile": "Subir archivo",
    "uploadMedia": "Cargar medios",
    "addAttachment": "Añadir un adjunto",
    "modalTitle": "Validación de archivo",
    "modalText": "El tipo de archivo es incorrecto!"
  },
  "navbar": {
    "sponsoredByThe": "Patrocinado por el ",
    "governmentOfCanada": "Gobierno de Canadá",
    "messageUs": "Envíenos sus preguntas o comentarios a"
  },
  "notifications": {
    "all": "Ver todos los notificaciones",
    "sentMessage": "{name} te ha mandado un mensaje",
    "uploadedZip": "{name} ha subido un archivo Zip con {type}",
    "startedTopic": "{name} ha creado una tema nueva"
  },
  "progressBars": {
    "circle": "Circulo",
    "horizontal": "Horizontal",
    "state": "Сondición",
    "colors": "Colores"
  },
  "lists": {
    "customers": "Clientes",
    "recentMessages": "Mensajes recientes",
    "archieved": "Archivado",
    "starterKit": "Kit de inicio",
    "notifications": "Notificaciones"
  },
  "tables": {
    "basic": "Tabla basica",
    "stripedHoverable": "Rayado, Hoverable",
    "labelsActions": "Etiquetas, Acciones como Botones",
    "sortingPaginationActionsAsIcons": "Clasificación, Paginación, Acciones como Iconos",
    "star": "Star",
    "unstar": "Unstar",
    "edit": "Editar",
    "delete": "Borrar",
    "searchByName": "Buscar por nombre",
    "searchTrendsBadges": "Búsqueda, Tendencias, Insignias",
    "perPage": "Por página",
    "report": "Informe",
    "infiniteScroll": "Scroll infinito",
    "selectable": "Seleccionable",
    "selected": "Seleccionado",
    "serverSidePagination": "Server-Side Paginación",
    "emptyTable": "Mesa vacía",
    "noDataAvailable": "Datos no disponibles.",
    "noReport": "No hay datos para mostrar. El informe estará disponible el 3 de noviembre de 2018.",
    "loading": "Cargando",
    "headings": {
      "email": "Email",
      "name": "Nombre",
      "firstName": "Nombre de pila",
      "lastName": "Apellido",
      "status": "Estado",
      "country": "País"
    }
  },
  "user": {
    "language": "Cambiar Idioma",
    "profile": "Perfil",
    "logout": "Cerrar Sesión",
    "wallet": "Billetera",
    "settings": "Ajustes",
    "faq": "Preguntas más frecuentes",
    "help": "Ayuda",
    "projects": "Proyectos"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Send"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs Alignment",
    "overflow": "Tabs Overflow",
    "hidden": "Tabs with Hidden slider",
    "grow": "Tabs Grow"
  },
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Lemag Admin",
  "search": {
    "placeholder": "Search..."
  },
  "buttonSelect": {
    "dark": "Dark",
    "light": "Light"
  }
}
