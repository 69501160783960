{
  "auth": {
    "agree": "Eu aceito.",
    "alreadyJoined": "Já entrou.",
    "createAccount": "Criar conta",
    "createNewAccount": "Criar uma nova conta",
    "email": "Email",
    "login": "Entrar",
    "password": "Senha",
    "signUp": "Cadastrar-se",
    "termsOfUse": "Termos de uso.",
    "welcome": "Bem vindo!"
  },
  "buttons": {
    "advanced": "Icones, Grupos, Dropdowns",
    "button": "BOTÃO",
    "colors": "Cores",
    "disabled": "DESABILITADO",
    "dropdown": "DROPDOWN",
    "hover": "HOVER",
    "main": "Primeiro & Segundo Botão",
    "micro": "MICRO",
    "pressed": "PRESSIONADO",
    "small": "PEQUENO"
  },
  "charts": {
    "horizontalBarChart": "Barra de Gráfico Horizontal",
    "verticalBarChart": "Barra de Gráfico Vertical",
    "lineChart": "Gráfico de Linha",
    "pieChart": "Gráfico Pizza",
    "donutChart": "Gráfico Rosca",
    "bubbleChart": "Gráfico Bolhas"
  },
  "dashboard": {
    "success": "SUCESSO",
    "successMessage": "Você leu com sucesso esta mensagem importante de alerta.",
    "elements": "Elementos",
    "teamMembers": "Membros da Equipe",
    "versions": "Versões",
    "info": {
      "verify": "Verificar",
      "revoke": "Revogar",
      "exploreGallery": "Explorar galeria",
      "dids": ",Identificadores Descentralizados",
      "vcs": "Credenciais verificáveis",
      "pr": "Solicitações de apresentação",
      "learnMore": "Saber mais",
      "learnMoreText": "Um emissor pode fornecer credenciais verificáveis ​​a um titular e então um verificador pode verificar a credencial por meio de uma solicitação de apresentação. Este processo é construído em torno de identificadores descentralizados (DIDs).",
      "shipmentsFrom": "Remessas de"
    },
    "table": {
      "title": "Mesa impressionante",
      "brief": "Breve",
      "detailed": "Detalhado",
      "resolve": "Resolver",
      "resolved": "Resolvido"
    }
  },
  "notificationsPage": {
    "alerts": {
      "title": "Alertas",
      "success": "SUCESSO",
      "successMessage": "Você leu com sucesso esta mensagem importante de alerta.",
      "info": "INFORMAÇÃO",
      "infoMessage": "Esse alerta precisa da sua atenção, mas não é super importante.",
      "warning": "AVISO",
      "warningMessage": "Melhor verificar a si mesmo, você não está muito bem.",
      "danger": "PERÍGO",
      "dangerMessage": "Mude algumas coisas e tente enviar novamente."
    },
    "popovers": {
      "title": "Dicas de ferramentas & Pop-Ups",
      "popoverTitleLabel": "Título Popover",
      "popoverTextLabel": "Texto Popover",
      "popoverIconLabel": "Icone Popover (fontawesome)",
      "showPopover": "Mostrar Popover",
      "topTooltip": "top dicas de ferramentas",
      "rightTooltip": "sugestão ao lado direito",
      "leftTooltip": "esquerda",
      "bottomTooltip": "abaixo"
    },
    "toasts": {
      "title": "Torradas",
      "textLabel": "Texto",
      "durationLabel": "Duração (milisegundos)",
      "iconLabel": "Icone (fontawesome)",
      "fullWidthLabel": "Largura completa",
      "launchToast": "Launch toast"
    }
  },
  "forms": {
    "controls": {
      "title": "Checkboxes, Radios, Switches",
      "radioDisabled": "Radio Desabilitado",
      "radio": "Radio",
      "subscribe": "Assine a newsletter"
    },
    "dateTimePicker": {
      "title": "Seletores de data e hora",
      "basic": "Básico",
      "time": "Hora",
      "range": "Alcance",
      "multiple": "Múltiplo",
      "manualInput": "Entrada manual",
      "disabled": "Desabilitado"
    },
    "inputs": {
      "emailValidatedSuccess": "Email (validado com sucesso)",
      "emailValidated": "Email (validado)",
      "inputWithIcon": "Entrada com ícone",
      "inputWithButton": "Entrada com botão",
      "inputWithClearButton": "Entrada com Botão Claro",
      "inputWithRoundButton": "Entrada com Botão Redondo",
      "textInput": "Entrada de Texto",
      "textInputWithDescription": "Entrada de Texto (com descrição)",
      "textArea": "Área de Texto",
      "title": "Entrada",
      "upload": "UPLOAD"
    },
    "mediumEditor": {
      "title": "Editor Medium"
    },
    "selects": {
      "country": "Selecionar Região",
      "countryMulti": "Selecionar Multiplas Regiões",
      "multi": "Selecionar Múltiplos",
      "simple": "Selecionador Simples",
      "title": "Selecionadores"
    }
  },
  "grid": {
    "desktop": "Grid Área de Trabalho",
    "fixed": "Grid Fixo",
    "offsets": "Offsets",
    "responsive": "Grid Responssivo"
  },
  "icons": {
    "back": "Retornar aos icones",
    "none": "Incones não encontrados",
    "search": "Pesquisar Icone",
    "title": "Icones"
  },
  "spinners": {
    "title": "Spinners",
    "poweredBy": "Distribuido por"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "Inglês",
    "french": "Francesa",
    "spanish": "Spanish",
    "simplified_chinese": "Mandarin",
    "persian": "Persa"
  },
  "menu": {
    "users": "de usuários",
    "products": "Produtos",
    "shipments": "Envios",
    "health": "Saúde",
    "did": "Identificadores descentralizados",
    "credentials": "Credenciais",
    "schema": "Esquema",
    "manifest": "Modelo",
    "template": "Modelo",
    "verifiableCredential": "Credencial verificável",
    "verification": "Verificação",
    "presentation": "Apresentação",
    "help": "Ajuda",
    "supplyChain": "Cadeia de mantimentos",
    "workflow": "Fluxo de trabalho",
    "faq2": "Perguntas frequentes",
    "submissionLink": "Solicitação de apresentação",
    "submission": "Submissão",
    "file": "arquivo",
    "files": "arquivos",
    "forms2": "Formulários",
    "upload": "Carregar",
    "upload2": "Carregar2",
    "billOfLading": "Conhecimento de embarque",
    "certOfOrigin": "Certificado de origem",
    "airWaybill": "Conhecimento Aéreo",
    "profile": "Perfil",
    "auth": "Autorização",
    "buttons": "Botões",
    "charts": "Gráficos",
    "colorPickers": "Colhedores de Cor",
    "dashboard": "Painel principal",
    "formElements": "Elementos de Formulários",
    "forms": "Formulários",
    "mediumEditor": "Editor Medium",
    "grid": "Grid",
    "icons": "Icones",
    "cards": "Cards",
    "spinners": "Spinners",
    "login": "Login",
    "maps": "Mapas",
    "modals": "Modals",
    "notifications": "Notificações",
    "progressBars": "Barra de progresso",
    "signUp": "Cadastrar-se",
    "statistics": "Estatística",
    "lists": "Listas",
    "tables": "Tabelas",
    "markupTables": "Tabelas de Marcação",
    "dataTables": "Tabelas de Dados",
    "typography": "Tipografia",
    "uiElements": "Elementos UI",
    "treeView": "Árvore de visualização",
    "dateTimePickers": "Selecionar Data e Hora",
    "TBDex": "TBDex",
    "pfis": "PFI",
    "offerings": "Ofertas",
    "rfqs": "RFQs",
    "chat": "Gráficos"
  },
  "messages": {
    "all": "Ver todas as menssagens",
    "new": "Nova menssagem de {name}"
  },
  "modal": {
    "cancel": "CANCELAR",
    "close": "FECHAR",
    "confirm": "CONFIRMAR",
    "large": "Grande",
    "largeTitle": "Modal Grande",
    "medium": "Médio",
    "mediumTitle": "Modal Médio",
    "small": "Pequeno",
    "smallTitle": "Modal Pequeno",
    "static": "Estático",
    "staticMessage": "Este é um modal é estático, clicar no pano de fundo não o fechará.",
    "staticTitle": "Modal Estático",
    "title": "Modals"
  },
  "navbar": {
    "sponsoredByThe": "Patrocinado pelo ",
    "governmentOfCanada": "Governo do Canadá",
    "messageUs": "Enviar uma pergunta ou feedback para"
  },
  "notifications": {
    "all": "Ver todas as notificações",
    "sentMessage": "{name} enviou uma menssagem",
    "uploadedZip": "{name} fez uploaded de um novo arquivo Zip com {type}",
    "startedTopic": "{name} iniciou um novo tópico"
  },
  "progressBars": {
    "circle": "Círculo",
    "horizontal": "Horizontal",
    "state": "Doença",
    "colors": "Cores"
  },
  "lists": {
    "customers": "Clientes",
    "recentMessages": "Mensagens Recentes",
    "archieved": "Arquivado",
    "starterKit": "Kit iniciante",
    "notifications": "Notificações"
  },
  "tables": {
    "basic": "Mesa Básica",
    "stripedHoverable": "Listrado, flutuante",
    "labelsActions": "Etiquetas, ações como botões",
    "sortingPaginationActionsAsIcons": "Ordenação, Paginação, Ações como Ícones",
    "star": "Star",
    "unstar": "Unstar",
    "edit": "Editar",
    "delete": "Excluir",
    "searchByName": "Procura por nome",
    "searchTrendsBadges": "Pesquisa, Tendências, Emblemas",
    "perPage": "Por página",
    "report": "Relatório",
    "infiniteScroll": "Rolagem infinita",
    "selectable": "Selecionável",
    "selected": "Selecionado",
    "serverSidePagination": "Server-Side Paginação",
    "emptyTable": "Mesa Vazia",
    "noDataAvailable": "Nenhum dado disponível.",
    "noReport": "Não há dados para exibir. O relatório estará disponível em 3 de novembro de 2018.",
    "loading": "Carregando",
    "headings": {
      "email": "Email",
      "name": "Nome",
      "firstName": "Primeiro nome",
      "lastName": "Último nome",
      "status": "Status",
      "country": "País"
    }
  },
  "user": {
    "language": "Trocar Linguagem",
    "logout": "Sair",
    "profile": "Perfil",
    "wallet": "Carteira",
    "settings": "Configurações",
    "faq": "Perguntas frequentes",
    "help": "Ajuda",
    "projects": "Projetos"
  },
  "treeView": {
    "basic": "Básico",
    "icons": "Icones",
    "selectable": "Selecionável",
    "editable": "Editável",
    "advanced": "Advançado"
  },
  "chat": {
    "title": "Gráficos"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs Alignment",
    "overflow": "Tabs Overflow",
    "hidden": "Tabs with Hidden slider",
    "grow": "Tabs Grow"
  },
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Lemag Admin",
  "search": {
    "placeholder": "Search..."
  },
  "buttonSelect": {
    "dark": "Dark",
    "light": "Light"
  }
}
