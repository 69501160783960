<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <!-- sc: 20240308 -->
      <!-- <RouterLink
        class="bg-primary h-full flex items-center justify-center"
        style="width: 35vw"
        to="/"
        aria-label="Visit homepage"
      >
        <VuesticLogo :height="28" start="#FFF" />
      </RouterLink> -->
      <!-- <RouterLink
        class="bg-backgroundBorder h-full flex items-center justify-center"
        style="width: 35vw"
        to="/"
        aria-label="Visit homepage"
      >
        <h1 class="h1">LEMAG</h1>
      </RouterLink> -->
      <div
        class="bg-backgroundBorder h-full flex justify-center"
        style="width: 30vw"
        to="/"
        aria-label="Visit homepage"
      >
        <h1 class="h1">
          <br />
          LEMAG
          <h5 class="va-h6" style="color: lightslategray">Supply Chain</h5>
          <h5 class="va-h6" style="color: lightslategray">Empowerment and</h5>
          <h5 class="va-h6" style="color: lightslategray">Beyond.</h5>
          <!-- <h5 class="va-h6" style="color: lightslategray">Empower your supply chain</h5>
          <h5 class="va-h6" style="color: lightslategray">management today!</h5> -->
          <!-- <h5 class="va-h6" style="color: lightslategray">Use bid proposal number</h5>
          <h5 class="va-h6" style="color: lightslategray">to login.</h5> -->
        </h1>
      </div>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <!-- <RouterLink class="py-4" to="/" aria-label="Visit homepage"> -->
            <!-- sc: 20240308 -->
            <!-- <VuesticLogo class="mb-2" start="#0E41C9" /> -->
            <h1 class="h1">LEMAG</h1>
            <!-- </RouterLink> -->
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
// import VuesticLogo from '../components/VuesticLogo.vue'

const breakpoint = useBreakpoint()
</script>
