// https://thisinterestsme.com/javascript-detect-user-activity/

// sc: 20240430
import router from '@src/router'

export function activityWatcher() {
  //The number of seconds that have passed
  //since the user was active.
  let minsSinceLastActivity = 0

  // Five minutes. 60 x 5 = 300 seconds.
  //   const maxInactivity = 60 * 5
  // const maxInactivity = 5 // 5 secs
  //   const maxInactivity = 1 // 1 min
  const maxInactivity = 3 // 3 min

  //Setup the setInterval method to run
  //every second. 1000 milliseconds = 1 second.
  setInterval(function () {
    minsSinceLastActivity++
    console.log(minsSinceLastActivity + ' mins since the user was last active')

    //if the user has been inactive or idle for longer
    //then the seconds specified in maxInactivity
    if (minsSinceLastActivity > maxInactivity) {
      //   console.log('User has been inactive for more than ' + maxInactivity + ' seconds')

      // sc: 20240513
      //Redirect them to your logout.php page.
      //   location.href = '/404'
      localStorage.removeItem('user')
      router.push('/auth/login')
    }
    //   }, 1000) // 1 sec
    //   }, 10000) // 10 secs
  }, 60000) // 60 secs
  //   }, 300000) // 300 secs = 5 mins

  //The function that will be called whenever a user is active
  function activity() {
    // //reset the secondsSinceLastActivity variable back to 0
    minsSinceLastActivity = 0
  }

  //An array of DOM events that should be interpreted as
  //user activity.
  const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']

  //add these events to the document.
  //register the activity function as the listener parameter.
  activityEvents.forEach(function (eventName) {
    document.addEventListener(eventName, activity, true)
  })
}
