import { defineStore } from 'pinia'
import { Web5 } from '@web5/api'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isSidebarMinimized: false,
      userName: 'S. Jones',
      web5: null as any, // sc: https://stackoverflow.com/questions/59715119/type-null-is-not-assignable-to-type-t
      myDid: '', // sc: 20240220
    }
  },

  actions: {
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    getMyDID() {
      return this.myDid
    },

    // sc:
    async initializeWeb5() {
      console.log('initializeWeb5 running...')

      const web5Result: any = await Web5.connect()

      // const { web5, did: myDid } = await Web5.connect()

      this.web5 = await web5Result.web5
      this.myDid = await web5Result.did

      // console.log('web5Result', web5Result)
      // console.log('this.web5', this.web5)
      console.log('this.myDid', this.myDid)
    },
  },
})
