{
  "TBD": {
    "IssuerDID": "DID de l'émetteur",
    "IssuerPrivateKey": "Clé privée IIssuer"
  },
  "auth": {
    "agree": "J'accepte",
    "createAccount": "Créer un compte",
    "createNewAccount": "Créer un nouveau compte",
    "e-mail": "E-mail",
    "login": "Connexion",
    "password": "Mot de passe",
    "recover_password": "Récupérer le mot de passe",
    "sign_up": "Inscrivez-vous",
    "keep_logged_in": "Garde-moi connecté",
    "termsOfUse": "Conditions d'utilisation.",
    "reset_password": "Réinitialiser le mot de passe"
  },
  "404": {
    "title": "Cette page est partie à la pêche.",
    "text": "Si vous pensez que ce n'est pas bien, envoyez-nous un message à ",
    "back_button": "Retour au tableau de bord"
  },
  "rating": {
    "singleIcon": "Icône unique",
    "twoIcons": "Deux icônes",
    "large": "Grand",
    "numbers": "Nombres",
    "halves": "Moitiés",
    "small": "Petit"
  },
  "typography": {
    "primary": "Styles de texte principaux",
    "secondary": "Styles de texte secondaires"
  },
  "colorPickers": {
    "simple": "Simple",
    "slider": "Curseur",
    "advanced": "Avancé"
  },
  "buttons": {
    "advanced": "Boutons avec icônes",
    "size": "Tailles des boutons",
    "tags": "Balises de bouton",
    "button": "Bouton",
    "buttonGroups": "Groupes de boutons",
    "buttonsDropdown": "Boutons avec liste déroulante",
    "split": "diviser",
    "splitTo": "Diviser en",
    "customIcon": "Icône personnalisée",
    "content": "Contenu",
    "buttonToggles": "Bouton bascule",
    "pagination": "Pagination",
    "a-link": "Ouvrir EpicSpinners",
    "router-link": "Déplacer vers les graphiques",
    "colors": "Couleurs des boutons",
    "disabled": "Désactivé",
    "dropdown": "DROPDOWN",
    "hover": " SURVERSER ",
    "types": "Types de boutons",
    "pressed": "PRESSÉ",
    "default": "Par défaut",
    "outline": "Contour",
    "flat": "Plat",
    "large": "Grand",
    "small": "Petit",
    "normal": "Normal",
    "success": "Succès",
    "infos": "Infos",
    "danger": "Danger",
    "warning": "Avertissement",
    "gray": "Gris",
    "dark": "Sombre"
  },
  "charts": {
    "horizontalBarChart": "Graphique à barres horizontales",
    "verticalBarChart": "Graphique à barres verticales",
    "lineChart": "Graphique linéaire",
    "pieChart": "Graphique circulaire",
    "donutChart": "Graphique en beignet",
    "bubbleChart": "Graphique à bulles"
  },
  "collapse": {
    "basic": "Réduction de base",
    "collapseWithBackground": "Réduire avec l'arrière-plan",
    "collapseWithCustomHeader": "Réduire avec un en-tête personnalisé",
    "firstHeader": "Développez ce bloc",
    "secondHeader": "Un autre bloc",
    "content": {
      "title": "De grandes choses",
      "text": "Il y a quelque chose dans la parentalité qui nous donne un sens de l'histoire et un désir profondément enraciné de transmettre à la prochaine génération les grandes choses que nous avons découvertes sur la vie."
    }
  },
  "slider": {
    "slider": "Curseurs",
    "range": "Plages",
    "simple": "simple",
    "value": "Valeur",
    "Label": "Étiquette",
    "labelPlusIcon": "étiquette + icône",
    "pins": "Épingles",
    "pinsAndValue": "pins et valeur",
    "withInput": "Avec entrée",
    "withIcons": "Avec icônes"
  },
  "popovers": {
    "popover": "popover",
    "popoverStyle": "Style de popover",
    "popoverPlacement": "Placement popover",
    "minimalTooltip": "Info-bulle minimale",
    "anotherOneTooltip": "Une autre info-bulle"
  },
  "datepickers": {
    "dateOfBirth": "Date de naissance",
    "daysOfTheWeek": "Jours de la semaine",
    "setupMeeting": "Configuration de la réunion",
    "upcomingVacation": "Vacances à venir",
    "multipleAndDisabledDates": "Dates multiples et désactivées",
    "inline": "En ligne"
  },
  "dashboard": {
    "versions": "Versions",
    "setupRemoteConnections": "Configurer les connexions à distance",
    "currentVisitors": "Visiteurs actuels",
    "charts": {
      "trendyTrends": "Tendances tendance",
      "showInMoreDetail": "Afficher plus en détail",
      "showInLessDetail": "Afficher avec moins de détails",
      "loadingSpeed": "Vitesse de chargement",
      "topContributors": "Meilleurs contributeurs",
      "showNextFive": "Afficher les cinq prochains",
      "commits": "Commits"
    },
    "info": {
      "verify": "Vérifier",
      "revoke": "Révoquer",
      "exploreGallery": "Explorer la galerie",
      "componentRichTheme": "thème riche en composants",
      "completedPullRequests": "demandes d'extraction terminées",
      "users": "utilisateurs",
      "points": "points",
      "units": "unités",
      "commits": "s'engage",
      "components": "composants",
      "teamMembers": "membres de l'équipe",
      "dids": "Identifiants décentralisés",
      "vcs": "Informations d'identification vérifiables",
      "pr": "Demandes de présentation",
      "learnMore": "En savoir plus",
      "learnMoreText": "Un émetteur peut fournir des informations d'identification vérifiables à un titulaire, puis un vérificateur peut vérifier les informations d'identification via une demande de présentation. Ce processus est construit autour d'identifiants décentralisés (DID).",
      "shipmentsFrom": "Expéditions de"
    },
    "table": {
      "title": "Superbe table",
      "brief": "Lettre",
      "detailed": "Détaillé",
      "resolve": "Résoudre",
      "resolved": "Résolu"
    },
    "tabs": {
      "overview": {
        "title": "Aperçu",
        "built": "Construit avec le framework Vue.js",
        "free": "Absolument gratuit pour tout le monde",
        "fresh": "Design frais et net",
        "mobile": "Responsive et optimisé pour les mobiles",
        "components": "Des tonnes de composants utiles",
        "nojQuery": "Entièrement gratuit avec jQuery"
      },
      "billingAddress": {
        "title": "Adresse de facturation",
        "personalInfo": "Informations personnelles",
        "firstName": "Prénom Nom de famille",
        "e-mail": "E-mail",
        "adress": "Adresse",
        "companyInfo": "Informations sur la société",
        "city": "Ville",
        "country": "Pays",
        "infiniteConnections": "Connexions infinies",
        "addConnection": "Ajouter une connexion"
      },
      "bankDetails": {
        "title": "Coordonnées bancaires",
        "detailsFields": "Champs de détails",
        "bankName": "Nom de la banque",
        "accountName": "Nom du compte",
        "sortCode": "Code de tri",
        "accountNumber": "Numéro de compte",
        "notes": "Remarques",
        "sendDetails": "Envoyer les détails"
      }
    },
    "navigationLayout": "disposition de navigation",
    "topBarButton": "Barre supérieure",
    "sideBarButton": "Barre latérale"
  },
  "notificationsPage": {
    "notifications": {
      "title": "Notifications",
      "gray": "Traitement",
      "dark": "Nouvelle étiquette",
      "success": "Payé",
      "successMessage": "Vous avez lu avec succès ce message d'alerte important.",
      "info": "Info",
      "infoMessage": "Cette alerte nécessite votre attention, mais elle n'est pas très importante.",
      "warning": "En attente",
      "warningMessage": "Mieux vaut vérifier par vous-même, vous n'avez pas l'air très bien.",
      "danger": "Danger",
      "dangerMessage": "Modifiez quelques éléments et réessayez de soumettre."
    },
    "popovers": {
      "title": "Info-bulles et popovers",
      "popoverTitleLabel": "Titre du popover",
      "popoverTextLabel": "Texte popover",
      "popoverIconLabel": "Icône Popover (fontawesome)",
      "showPopover": "Afficher le popover",
      "topTooltip": "haut",
      "rightTooltip": "côté droit",
      "leftTooltip": "gauche",
      "bottomTooltip": "en dessous"
    },
    "toasts": {
      "title": "Toasts",
      "textLabel": "Texte",
      "durationLabel": "Durée (millisecondes)",
      "iconLabel": "Icône (fontawesome)",
      "fullWidthLabel": "Pleine largeur",
      "launchToast": "Lancer le toast"
    }
  },
  "forms": {
    "controls": {
      "title": "Boxes à cocher, radios, commutateurs, bascules",
      "radioDisabled": "Radio désactivée",
      "radio": "Radio",
      "subscribe": "S'abonner à la newsletter",
      "unselected": "Case à cocher non sélectionnée",
      "selected": "Case à cocher sélectionnée",
      "readonly": "Case à cocher en lecture seule",
      "disabled": "Case à cocher désactivée",
      "error": "Case à cocher avec erreur",
      "errorMessage": "Case à cocher avec messages d'erreur"
    },
    "dateTimePicker": {
      "title": "Sélecteurs de date et d'heure",
      "basic": "Basique",
      "time": "Temps",
      "range": "Plage",
      "manualInput": "Saisie manuelle",
      "multiple": "Multiple",
      "disabled": "Désactivé",
      "customFirstDay": "Premier jour personnalisé",
      "customDateFormat": "Format de date personnalisé"
    },
    "inputs": {
      "emailValidatedSuccess": "E-mail (validé avec succès)",
      "emailValidated": "E-mail (validé)",
      "inputWithIcon": "Saisie avec icône",
      "inputWithButton": "Saisie avec bouton",
      "inputWithClearButton": "Saisie avec le bouton Effacer",
      "inputWithRoundButton": "Saisie avec bouton rond",
      "textInput": "Saisie de texte",
      "textInputWithDescription": "Saisie de texte (avec description)",
      "textArea": "Zone de texte",
      "title": "Entrées",
      "upload": "Télécharger"
    },
    "mediumEditor": {
      "title": "Éditeur moyen"
    },
    "selects": {
      "country": "Sélection du pays",
      "countryMulti": "Sélection multiple du pays",
      "multi": "Sélection multiple",
      "simple": "Sélection simple",
      "searchable": "Sélectionner avec recherche",
      "searchableMulti": "Sélection multiple avec recherche",
      "title": "Sélectionne"
    }
  },
  "grid": {
    "desktop": "Grille du bureau",
    "fixed": "Grille fixe",
    "offsets": "Décalages",
    "responsive": "Grille réactive"
  },
  "icons": {
    "back": "Retour à toutes les icônes",
    "none": "Aucune icône trouvée",
    "search": "Recherche d'icônes",
    "title": "Icônes"
  },
  "spinners": {
    "title": "Spinners",
    "poweredBy": "Propulsé par"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "Anglais",
    "french": "Française",
    "spanish": "Espagnol",
    "simplified_chinese": "Mandarin",
    "persan": "Persan"
  },
  "menu": {
    "users": "L’utilisateurs",
    "products": "Produits",
    "shipments": "Expéditions",
    "shipmentList": "Liste d'expédition",
    "shipmentDetail": "Détails de l'expédition",
    "health": "Santé",
    "did": "Identifiants Décentralisés",
    "credentials": "Informations d'identification",
    "schema": "Schéma",
    "manifest": "Modèle",
    "template": "Modèle",
    "verifiableCredential": "Titre vérifiable",
    "verification": "Vérification",
    "presentation": "Présentation",
    "help": "Aide",
    "supplyChain": "Chaîne d'approvisionnement",
    "workflow": "Flux de travail",
    "faq2": "FAQ",
    "submissionLink": "Demande de présentation",
    "submission": "Soumission",
    "file": "Le dossier",
    "files": "Des dossiers",
    "forms2": "Formes",
    "upload": "Télécharger",
    "upload2": "Télécharger2",
    "billOfLading": "Connaissement",
    "certOfOrigin": "Certificat d'origine",
    "airWaybill": "Lettre de transport aérien",
    "profile": "Profil",

    "auth": "Authentifier",
    "rating": "Note",
    "buttons": "Boutons",
    "charts": "Graphiques",
    "colorPickers": "Sélecteurs de couleurs",
    "collapses": "S'effondre",
    "timelines": "Chronologies",
    "dashboard": "Tableau de bord",
    "formElements": "Éléments de formulaire",
    "forms": "Formulaires",
    "mediumEditor": "Éditeur moyen",
    "grid": "Grille",
    "icons": "Icônes",
    "cards": "Cartes",
    "spinners": "Spinners",
    "login": "Connexion",
    "maps": "Cartes",
    "pages": "Pages",
    "modals": "Modaux",
    "notifications": "Notifications",
    "progressBars": "Barres de progression",
    "signUp": "Inscrivez-vous",
    "statistics": "Statistiques",
    "lists": "Listes",
    "tables": "Tableaux",
    "markupTables": "Tables de balisage",
    "dataTables": "Tableaux de données",
    "chips": "Chips",
    "tabs": "Onglets",
    "typography": "Typographie",
    "uiElements": "Éléments de l'interface utilisateur",
    "treeView": "Arborescence",
    "dateTimePickers": "Sélecteurs de date et d'heure",
    "fileUpload": "Téléchargement de fichier",
    "colors": "Couleurs",
    "spacing": "Espacement",
    "sliders": "Curseurs",
    "popovers": "popovers",
    "TBDex": "TBDex",
    "pfi": "PFIs",
    "offerings": "Offres",
    "rfqs": "RFQs",
    "chat": "Chatter",
    "maplibre-maps": "Cartes MapLibre",
    "yandex-maps": "Cartes Yandex",
    "leaflet-maps": "Cartes de dépliants",
    "bubble-maps": "Cartes à bulles",
    "line-maps": "Plans de lignes",
    "login-singup": "Connexion/Inscription",
    "404 pages": "404 pages",
    "faq": "Faq"
  },
  "messages": {
    "all": "Voir tous les messages",
    "new": "Nouveaux messages de {name}",
    "mark_as_read": "Marquer comme lu"
  },
  "modal": {
    "cancel": "Annuler",
    "close": "Fermer",
    "confirm": "Confirmer",
    "large": "Grand",
    "largeTitle": "Grand Modal",
    "medium": "Moyen",
    "mediumTitle": "Moyen Modal",
    "small": "Petit",
    "smallTitle": "Petit Modal",
    "static": "Statique",
    "staticMessage": "Il s'agit d'un modal statique, un clic en arrière-plan ou en échappement ne le fermera pas.",
    "staticTitle": "Modal statique",
    "title": "Modaux",
    "fullscreen": "Plein écran",
    "titleOptions": "Options modales",
    "blurred": "Superposition floue",
    "withAnchorSlot": "Avec emplacement d'ancrage",
    "message": "Il existe trois espèces de zèbres : le zèbre des plaines, le zèbre des montagnes et le zèbre de Grévy. Le zèbre des plaines et le zèbre des montagnes appartiennent au sous-genre Hippotigris, mais le zèbre\n de Grévy est la seule espèce du sous-genre\n Dolichohippus. Ce dernier ressemble à un âne, auquel il est étroitement apparenté, tandis que les deux premiers ressemblent davantage à un cheval. Tous trois appartiennent au genre Equus, avec d'autres équidés vivants."
  },
  "dropdown": {
    "default": "Par défaut",
    "withArrow": "Avec flèche",
    "note": "Remarque",
    "noteText": "La liste déroulante s'ouvrira dans la direction spécifiée s'il y a suffisamment d'espace sur l'écran, sinon la direction changera automatiquement",
    "top": "HAUT",
    "right": "DROITE",
    "bottom": "BAS",
    "left": "GAUCHE"
  },
  "fileUpload": {
    "advancedMediaGallery": "Avancé, Galerie Média",
    "advancedUploadList": "Avancé, Liste de téléchargement",
    "mediaGallery": "Galerie Média",
    "uploadList": "Télécharger la liste",
    "single": "Célibataire",
    "dragNdropFiles": "Faites glisser et déposez des fichiers ou",
    "uploadedOn": "Téléchargé le",
    "fileDeleted": "Le fichier a été supprimé avec succès",
    "undo": "Annuler",
    "preview": "Aperçu",
    "delete": "Supprimer",
    "deleteFile": "Supprimer le fichier",
    "uploadFile": "Télécharger le fichier",
    "uploadMedia": "Télécharger le média",
    "addAttachment": "Ajouter une pièce jointe",
    "modalTitle": "Validation du fichier",
    "modalText": "Le type de fichier est incorrect !"
  },
  "chips": {
    "chips": {
      "title": "Chips",
      "primary": "Puce principale",
      "secondary": "Puce secondaire",
      "success": "Puce de réussite",
      "info": "Puce d'information",
      "danger": "Puce de danger",
      "warning": "Puce d'avertissement",
      "gray": "Puce grise",
      "dark": "Puce sombre"
    },
    "badges": {
      "title": "Badges",
      "primary": "Badge principal",
      "secondary": "Badge secondaire",
      "success": "Badge de réussite",
      "info": "Badge info",
      "danger": "Insigne de danger",
      "warning": "Badge d'avertissement",
      "gray": "Badge gris",
      "dark": "Badge sombre"
    }
  },
  "navbar": {
    "sponsoredByThe": "Parrainé par le ",
    "governmentOfCanada": "Gouvernement du Canada",
    "messageUs": "Demandes de développement Web :",
    "repository": "Dépôt GitHub"
  },
  "notifications": {
    "all": "Voir toutes les notifications",
    "mark_as_read": "Marquer comme lu",
    "sentMessage": "vous a envoyé un message",
    "uploadedZip": "a téléchargé un nouveau fichier Zip avec {type}",
    "startedTopic": "a commencé un nouveau sujet"
  },
  "timelines": {
    "horizontalSimple": "Horizontal Simple",
    "horizontalCards": "Cartes horizontales",
    "verticalSimple": "VerticalSimple",
    "verticalLabel": "Vertical avec étiquette",
    "verticalCentered": "Centré verticalement",
    "horizontalActionFirst": "Terminer les brouillons",
    "horizontalActionSecond": "Pousser le site en direct",
    "horizontalActionThird": "Démarrer l'ICO",
    "titleFirst": "Créer un design",
    "titleSecond": "Développer une application",
    "titleThird": "Soumettre une application",
    "titreDatePremier": "",
    "titleDateSecond": "22 mai 10h00",
    "titleDateThird": "19 juillet 17h45",
    "firstDate": "Février 2018",
    "secondDate": "Mars 2018",
    "thirdDate": "Avril 2018",
    "contentFirst": "Les rayures uniques des zèbres en font l'un des animaux les plus familiers aux humains. On les rencontre dans une variété d'habitats, tels que les prairies, les savanes, les forêts, les garrigues épineuses.",
    "contentSecond": "Ils sont présents dans une variété d'habitats, tels que les prairies, les savanes, les forêts, les garrigues épineuses.",
    "contentThird": "Cependant, divers facteurs anthropiques ont eu un impact sévère sur les populations de zèbres"
  },
  "progressBars": {
    "circle": "Cercle",
    "horizontal": "Horizontal",
    "state": "État",
    "colors": "Couleurs"
  },
  "lists": {
    "customers": "Clients",
    "recentMessages": "Messages récents",
    "archieved": "Archivé",
    "starterKit": "Kit de démarrage",
    "notifications": "Notifications",
    "routerSupport": "Support du routeur"
  },
  "tables": {
    "basic": "Tableau de base",
    "stripedHoverable": "Rayé, Hoverable",
    "labelsActions": "Étiquettes, actions sous forme de boutons",
    "sortingPaginationActionsAsIcons": "Tri, Pagination, Actions sous forme d'icônes",
    "star": "Étoile",
    "unstar": "Unstar",
    "edit": "Modifier",
    "delete": "Supprimer",
    "searchByName": "Rechercher par nom",
    "searchTrendsBadges": "Recherche, tendances, badges",
    "parPage": "Par page",
    "report": "Rapport",
    "infiniteScroll": "Parchemin infini",
    "selectable": "Sélectionnable",
    "selected": "Sélectionné",
    "serverSidePagination": "Pagination côté serveur",
    "emptyTable": "Table vide",
    "noDataAvailable": "Aucune donnée disponible.",
    "noReport": "Il n'y a aucune donnée à afficher. Le rapport sera disponible le 3 novembre 2018.",
    "loading": "Chargement",
    "headings": {
      "e-mail": "E-mail",
      "first Name": "Prénom",
      "Last Name": "Nom de famille",
      "status": "Statut",
      "country": "Pays",
      "location": "Emplacement"
    }
  },
  "user": {
    "language": "Changer la langue",
    "logout": "Déconnexion",
    "profile": "Profil",
    "wallet": "Le Portefeuille",
    "settings": "Paramètres",
    "faq": "FAQ",
    "help": "Aide",
    "projects": "Projets"
  },
  "TreeView": {
    "basic": "Basique",
    "icons": "Icônes",
    "selectable": "Sélectionnable",
    "editable": "Modifiable",
    "advanced": "Avancé"
  },
  "chat": {
    "title": "Discussion",
    "sendButton": "Envoyer"
  },
  "spacingPlayground": {
    "value": "Valeur",
    "margin": "Marge",
    "padding": "Rembourrage"
  },
  "spacing": {
    "title": "Espacement"
  },
  "cards": {
    "cards": "Cartes",
    "fixed": "Fixé",
    "floating": "Flottant",
    "contentText": "Les rayures uniques des zèbres en font l'un des animaux les plus familiers aux humains.",
    "contentTextLong": "Les rayures uniques des zèbres en font l'un des animaux les plus familiers aux humains. Ils sont présents dans une variété d'habitats, tels que les prairies, les savanes, les forêts, les garrigues épineuses, les montagnes et les collines côtières. Divers facteurs anthropiques ont a eu un impact sévère sur les populations de zèbres, notamment la chasse pour la peau et la destruction de l'habitat. Le zèbre de Grévy et le zèbre des montagnes sont en voie de disparition. Alors que les zèbres des plaines sont beaucoup plus abondants, une sous-espèce, le quagga.",
    "rowHeight": "Hauteur de la ligne",
    "title": {
      "default": "Par défaut",
      "withControls": "Avec contrôles",
      "customHeader": "En-tête personnalisé",
      "withoutHeader": "Sans en-tête",
      "withImage": "Avec Image",
      "withTitleOnImage": "Avec titre sur l'image",
      "withCustomTitleOnImage": "Avec titre personnalisé sur l'image",
      "withStripe": "Avec bande",
      "withBackground": "Avec arrière-plan"
    },
    "button": {
      "main": "Principal",
      "cancel": "Annuler",
      "showMore": "Afficher plus",
      "readMore": "Afficher plus"
    },
    "link": {
      "edit": "Modifier",
      "setAsDefault": "Définir par défaut",
      "delete": "Supprimer",
      "traveling": "Voyager",
      "france": "France",
      "review": "Revue",
      "feedback": "Laisser un commentaire",
      "readFull": "Lire l'article complet",
      "secondaryAction": "Action secondaire",
      "action1": "Action1",
      "action2": "Action2"
    }
  },
  "colors": {
    "themeColors": "Couleurs du thème",
    "extraColors": "Couleurs supplémentaires",
    "gradients": {
      "basic": {
        "title": "Dégradés des boutons"
      },
      "hovered": {
        "title": "Dégradés des boutons survolés",
        "text": "Éclaircir 15 % appliqué à un style original (dégradé ou couleur plate) pour l'état de survol."
      },
      "pressed": {
        "title": "Dégradés des boutons enfoncés",
        "text": "Assombrir 15 % appliqué à un style original (dégradé ou aplat) pour l'état pressé."
      }
    }
  },
  "tabs": {
    "alignment": "Alignement des onglets",
    "overflow": "Débordement d'onglets",
    "hidden": "Onglets avec curseur caché",
    "grow": "Les onglets s'agrandissent"
  }
}
