// https://jasonwatmore.com/post/2022/05/26/vue-3-pinia-jwt-authentication-tutorial-example
// https://stackblitz.com/edit/vue-3-pinia-jwt-authentication-tutorial-and-example?file=src%2FApp.vue
// https://jasonwatmore.com/nodejs-jwt-authentication-tutorial-with-example-api
// https://jasonwatmore.com/post/2022/06/07/vue-3-redirect-to-login-page-if-unauthenticated

export { fakeBackend }

function fakeBackend() {
  const users: any = [
    { id: 1, username: 'canada', password: 'canada', firstName: 'canada', lastName: 'User' },
    { id: 2, username: '019415', password: '019415', firstName: '019415', lastName: 'User' },
  ]
  const realFetch = window.fetch
  window.fetch = function (url: any, opts: any) {
    return new Promise((resolve: any, reject: any) => {
      // wrap in timeout to simulate server api call
      setTimeout(handleRoute, 500)

      function handleRoute() {
        switch (true) {
          case url.endsWith('/users/authenticate') && opts.method === 'POST':
            return authenticate()
          case url.endsWith('/users') && opts.method === 'GET':
            return getUsers()
          default:
            // pass through any requests not handled above
            return realFetch(url, opts)
              .then((response) => resolve(response))
              .catch((error) => reject(error))
        }
      }

      // route functions

      function authenticate() {
        // console.log('Running authenticate...')
        const { username, password } = body()
        // console.log('authenticate...username', username)
        // console.log('authenticate...password', password)

        const user = users.find((x: any) => x.username === username && x.password === password)

        if (!user) return error('Username or password is incorrect')

        return ok({
          id: user.id,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          token: 'fake-jwt-token',
        })
      }

      function getUsers() {
        if (!isAuthenticated()) return unauthorized()
        return ok(users)
      }

      // helper functions

      function ok(body: any) {
        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) })
      }

      function unauthorized() {
        resolve({ status: 401, text: () => Promise.resolve(JSON.stringify({ message: 'Unauthorized' })) })
      }

      function error(message: any) {
        resolve({ status: 400, text: () => Promise.resolve(JSON.stringify({ message })) })
      }

      function isAuthenticated() {
        return opts.headers['Authorization'] === 'Bearer fake-jwt-token'
      }

      function body() {
        return opts.body && JSON.parse(opts.body)
      }
    })
  }
}
