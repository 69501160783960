import { defineStore } from 'pinia'

// import { fetchWrapper, router } from '@/helpers'
import router from '@src/router'
import { fetchWrapper } from './helpers'

const baseUrl: any = `${import.meta.env.VITE_API_URL}/users`
// console.log('baseUrl', baseUrl)

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    // user: JSON.parse(localStorage.getItem('user')), // sc: 20240501: original line, but doesn't work!
    user: JSON.parse(JSON.stringify(localStorage.getItem('user'))),

    // user: JSON.stringify(localStorage.getItem('user')),
    // user: localStorage.getItem('user'),

    returnUrl: null,
  }),
  actions: {
    async login(username: any, password: any) {
      // console.log('username2', username)
      // console.log('password2', password)
      // console.log(
      //   'JSON.parse(JSON.stringify(localStorage.getItem(user)))',
      //   JSON.parse(JSON.stringify(localStorage.getItem('user'))),
      // )
      // console.log('JSON.stringify(localStorage.getItem(user)))', JSON.stringify(localStorage.getItem('user')))
      // // console.log('JSON.parse(localStorage.getItem(user)))', JSON.parse(localStorage.getItem('user')))
      // console.log('localStorage.getItem(user))', localStorage.getItem('user'))

      const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password })
      // console.log('user', user)
      // update pinia state
      this.user = user

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user))

      // redirect to previous url or default to home page
      router.push(this.returnUrl || '/')

      return this.returnUrl || '/'
    },
    logout() {
      this.user = null
      localStorage.removeItem('user')
      router.push('/auth/login')
    },
  },
})
