import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

// sc: 20240430
import { useAuthStore } from '@src/stores/auth'

// import {
//   ActivateActivityTracker,
//   deactivateActivityTracker,
//   userActivityTimeout,
//   userActivityThrottlerTimeout,
// } from '@src/pages/auth/Timeout.vue'

// sc: 20240510
import { activityWatcher } from '@src/stores/auth/helpers/timeout-wrapper'

// import {
//   activateActivityTracker,
//   deactivateActivityTracker,
//   userActivityTimeout,
//   userActivityThrottlerTimeout,
// } from '@src/stores/auth/helpers/timeout-wrapper'

// sc: 20240308 - removed
// sc: 20240220
// import UIRoute from '@pages/admin/ui/route'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@pages/admin/dashboard/Dashboard.vue'),
      },
      // sc: 20240214
      {
        name: 'users',
        path: 'users',
        component: () => import('@pages/users/UsersPage.vue'),
      },
      {
        name: 'products',
        path: 'products',
        component: () => import('@pages/supply/products/ProductsPage.vue'),
      },
      {
        name: 'shipments',
        path: 'shipments',
        component: () => import('@src/pages/supply/shipments/ShipmentsPage.vue'),
      },
      {
        name: 'files',
        path: 'files',
        component: () => import('@src/pages/supply/files/FilesPage.vue'),
      },
      {
        name: 'forms',
        path: 'forms',
        component: RouteViewComponent,
        children: [
          {
            name: 'billOfLading',
            path: 'billOfLading',
            component: () => import('@src/pages/supply/forms/billOfLading/BillOfLading.vue'),
          },
          {
            name: 'certOfOrigin',
            path: 'certOfOrigin',
            component: () => import('@src/pages/supply/forms/certOfOrigin/CertOfOrigin.vue'),
          },
          {
            name: 'airWaybill',
            path: 'airWaybill',
            component: () => import('@src/pages/supply/forms/airWaybill/AirWaybill.vue'),
          },
        ],
      },
      // {
      //   name: 'health',
      //   path: 'health',
      //   component: () => import('@pages/ssi/health/Health.vue'),
      // },
      {
        name: 'did',
        path: 'did',
        component: () => import('@pages/ssi/did/DidPage.vue'),
      },
      {
        // name: 'credentials',
        // path: 'credentials',
        name: 'template',
        path: 'template',
        component: RouteViewComponent,
        children: [
          {
            name: 'schema',
            path: 'schema',
            // component: () => import('@pages/ssi/credentials/schema/SchemaPage.vue'),
            component: () => import('@pages/ssi/template/schema/SchemaPage.vue'),
          },
          {
            name: 'manifest',
            path: 'manifest',
            // component: () => import('@pages/ssi/credentials/manifest/ManifestPage.vue'),
            component: () => import('@pages/ssi/template/manifest/ManifestPage.vue'),
          },
        ],
      },
      {
        name: 'verifiableCredential',
        path: 'verifiableCredential',
        component: () => import('@pages/ssi/verifiable-credential/VCPage.vue'),
      },
      // {
      //   name: 'verification',
      //   path: 'verification',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'submissionLink',
      //       path: 'submissionLink',
      //       // component: () => import('@pages/ssi/verification/submissionLink/SubmissionLink.vue'),
      //       component: () => import('../pages/ssi/verification/submissionLink/SubmissionLink.vue'),
      //     },
      //     {
      //       name: 'submission',
      //       path: 'submission',
      //       component: () => import('@pages/ssi/verification/submission/Submission.vue'),
      //     },
      //   ],
      // },
      {
        name: 'presentation',
        path: 'presentation',
        component: RouteViewComponent,
        children: [
          {
            name: 'request',
            path: 'request',
            component: () => import('@pages/ssi/presentation/request/RequestPage.vue'),
          },
          {
            name: 'submission',
            path: 'submission',
            component: () => import('@pages/ssi/presentation/submission/SubmissionPage.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: 'faq',
        // component: () => import('@pages/faq/FaqPage.vue'),
        component: () => import('@pages/help/widgets/Faq.vue'),
      },
      // {
      //   name: 'help',
      //   path: 'help',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'learnMore',
      //       path: 'learnMore',
      //       component: () => import('@pages/help/widgets/LearnMore.vue'),
      //     },
      //     {
      //       name: 'workflow',
      //       path: 'workflow',
      //       component: () => import('@pages/help/widgets/Workflow.vue'),
      //     },
      //   ],
      // },
      // {
      //   name: 'tbdex',
      //   path: 'tbdex',
      //   component: () => import('@pages/tbdex/rfq/RFQPage.vue'),
      // },
      {
        name: 'tbdex',
        path: 'tbdex',
        component: RouteViewComponent,
        children: [
          {
            name: 'pfis',
            path: 'pfis',
            component: () => import('@pages/tbdex/pfis/PFIsPage.vue'),
          },
          {
            name: 'offerings',
            path: 'offerings',
            component: () => import('@pages/tbdex/offerings/OfferingsPage.vue'),
          },
          {
            name: 'rfqs',
            path: 'rfqs',
            component: () => import('@pages/tbdex/rfqs/RFQsPage.vue'),
          },
        ],
      },
      {
        name: 'help',
        path: 'help',
        component: () => import('@pages/help/HelpPage.vue'),
      },
      {
        name: 'learnMore',
        path: 'learnMore',
        component: () => import('@pages/help/widgets/LearnMore.vue'),
      },
      {
        name: 'workflow',
        path: 'workflow',
        component: () => import('@pages/help/widgets/Workflow.vue'),
      },
      {
        name: 'wallet',
        path: 'wallet',
        component: () => import('@pages/wallet/Wallet.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      // {
      //   name: 'chat',
      //   path: 'chat',
      //   component: () => import('../pages/chat/Chat.vue'),
      // },

      // UIRoute, // sc: 20240308 - removed
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  // {
  //   name: '404',
  //   path: '/404',
  //   component: () => import('../pages/404.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  linkActiveClass: 'active', // sc: 20240501 - used for login?
  routes,
})

// sc: 20240430
router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login']
  const authRequired = !publicPages.includes(to.path)
  const auth = useAuthStore()
  console.log('router.beforeEach...to', to)
  console.log('authRequired', authRequired)
  console.log('auth.user', auth.user)
  if (authRequired && !auth.user) {
    auth.returnUrl = Object(to.fullPath)
    console.log('auth.returnUrl', auth.returnUrl)
    return '/auth/login'
  }

  // sc: 20240510
  activityWatcher()

  // activateActivityTracker()
  // ActivateActivityTracker()
})

// router.afterEach(async () => {
//   deactivateActivityTracker()
//   clearTimeout(userActivityTimeout)
//   clearTimeout(userActivityThrottlerTimeout)
// })

export default router
