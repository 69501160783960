<template>
  <VaSidebar :width="width" :minimized="minimized" :minimized-width="minimizedWidth" :animated="animated">
    <!-- sc: 20240307 -->
    <!-- <MenuMinimized v-if="minimized" :items="items" />
    <MenuAccordion v-else :items="items" /> -->
    <MenuMinimized v-if="minimized" :items="itemsNew" />
    <MenuAccordion v-else :items="itemsNew" />
  </VaSidebar>
</template>

<script setup lang="ts">
import { ref, toRaw } from 'vue'
import NavigationRoutes from './NavigationRoutes'
import MenuAccordion from './menu/MenuAccordion.vue'
import MenuMinimized from './menu/MenuMinimized.vue'

withDefaults(
  defineProps<{
    width?: string
    color?: string
    animated?: boolean
    minimized?: boolean
    minimizedWidth?: string
  }>(),
  {
    width: '19rem', // '16rem',
    color: 'secondary',
    animated: true,
    minimized: true,
    minimizedWidth: undefined,
    // minimizedWidth: '0', // sc: 20240229
  },
)

const items = ref(NavigationRoutes.routes)

// sc: 20240307
const itemsNew: any = ref([])
// console.log('toRaw(items.value)', toRaw(items.value))

// sc: 20240307
const removeItem = (arr: any, itemsToRemove: string[]) => {
  const newArray = [...arr]

  itemsToRemove.map((itemToRm) => {
    // console.log('itemToRm', itemToRm)
    const index = newArray.findIndex((item) => toRaw(item.name) === itemToRm)
    // console.log('array index', index)
    if (index !== -1) {
      newArray.splice(index, 1)
    }
  })
  return toRaw(newArray)
}

// sc: 20240307 - remove these items on sidebar, but keep in navagation routes so that the componenents displays
//                when click under the my profile dropdown menu
itemsNew.value = removeItem(items.value, [
  'projects',
  'payments',
  'preferences',
  'settings',
  'workflow',
  'learnMore',
  'faq',
])
// console.log('itemsNew.value', itemsNew.value)
</script>

<style lang="scss">
.va-sidebar {
  &__menu {
    padding: 2rem 0;
  }

  &-item {
    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
